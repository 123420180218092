import React from 'react'
import { StaticImage } from 'gatsby-plugin-image'
import { ArrowRightIcon } from '@heroicons/react/20/solid'

import { Link } from 'gatsby'
import { Layout } from '../components/Layout'
import SEO from '../components/seo'
import { BigLink } from '../components/UI'
import cx from 'classnames'

const IndexPage = () => (
  <Layout stickyHeader>
    <SEO title="Freelance Frontend Engineer" />

    <div className="hidden sm:block absolute top-0 right-0 w-1/2 h-full z-10">
      <StaticImage
        src="../images/konstantin-glasses.jpg"
        alt="Konstantin Werner"
        className="w-full h-full"
      />
    </div>

    <div className="px-3 mx-auto sm:max-w-xl md:max-w-2xl lg:max-w-3xl">
      <div className="sm:w-1/2 min-h-screen flex flex-wrap flex-col justify-center py-8 pr-3">
        <p className="font-accent text-3xl sm:text-4xl w-full">
          👋
          <span className="inline-block mt-2">
            Hi, I&apos;m Konstantin,
            <br /> a freelance web and app developer.
          </span>
        </p>

        <div className="mt-10">
          <BigLink to="portfolio">See my work</BigLink>
        </div>
      </div>
    </div>
  </Layout>
)

export default IndexPage
